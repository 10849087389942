import { PICTURE_BASE_URL } from '../config/config';
import {
  Marking,
  Product,
  Image,
  SafetyDataInformation
} from '../types/xim/product';
import { Marking as EpiserverMarking } from '../types/episerver/marking';
import { addDays } from 'date-fns';
import {
  IExtendedMarking,
  IMappedSafetyDataSheetInformation
} from '../types/storefront/product';
import { SignalWords } from '../types/episerver/signalWords';
import { WarningSymbol } from '../types/episerver/warningSymbol';
import { capitalizeWord } from '../lib/utils';
import { ILoop54Product, IProductStoreDetails } from '../types/loop54Product';
import { IMAGE_PATHS } from '../constants/imagePaths';

export const loopDetermineAvailable = (
  details: IProductStoreDetails | null
) => {
  if (!details) {
    return false;
  }
  return (
    new Date(details?.p_availablefrom) <= addDays(new Date(), 1) &&
    (details?.p_availableto === null ||
      new Date(details?.p_availableto) >= new Date())
  );
};

export const setImage = (
  images: Image[] | null,
  type: number,
  path = IMAGE_PATHS.PRODUCTS
) => {
  const image = (images || []).find(image => image.type === type);

  return image
    ? { ...image, url: `${PICTURE_BASE_URL}/${path}/${image.url}` }
    : { url: '', alt: '', type: 0 };
};

export const determineMarkingVariant = (
  markings: Marking[] | null,
  sweetMarkings?: EpiserverMarking[] | null
): IExtendedMarking[] => {
  if (markings && sweetMarkings) {
    let out: IExtendedMarking[] = [];

    markings
      .filter((e, i) => markings?.findIndex(a => a['code'] === e['code']) === i)
      .forEach(marking => {
        const extendedProduct = sweetMarkings?.find(
          sweetMarking => sweetMarking.code === marking.code
        );
        if (extendedProduct) {
          out.push({
            ...marking,
            ...extendedProduct
          });
        }
      });

    return out;
  } else return [];
};

export const determineSafetyDataSheetInformation = (
  safetyDataSheetInformation: SafetyDataInformation | null,
  sweetenedSignalWords?: SignalWords[],
  sweetenedSymbols?: WarningSymbol[]
) => {
  const signalWordQualifier = 'http://www.unece.org/GHS/SignalWord';
  const symbolQualifier = 'http://www.unece.org/GHS/SymbolDescription';
  const highlightedInformation =
    (((safetyDataSheetInformation || {}).hazardInformations || [])[0] || {})
      .hazardCodes !== undefined
      ? safetyDataSheetInformation?.hazardInformations?.[0]?.hazardCodes?.reduce(
          (obj: IMappedSafetyDataSheetInformation, code) => {
            let matchedMeta;
            const isSignalWord = code.qualifier === signalWordQualifier;
            const isSymbol = code.qualifier === symbolQualifier;

            if (isSignalWord) {
              matchedMeta =
                sweetenedSignalWords &&
                sweetenedSignalWords.find(m => m.code === code.code);
            } else if (isSymbol) {
              matchedMeta =
                sweetenedSymbols &&
                sweetenedSymbols.find(m => m.code === code.code);
            }

            const res = {
              ...code,
              image:
                matchedMeta && matchedMeta.image
                  ? {
                      url: matchedMeta.image.url,
                      altText: matchedMeta.altText
                    }
                  : null,
              description:
                matchedMeta && matchedMeta.description
                  ? matchedMeta.description
                  : code.descriptions && code.descriptions.length > 0
                  ? code.descriptions[0].value
                  : null
            };

            if (isSignalWord || isSymbol) {
              obj['highlightedInformation'].push(res);
            } else {
              obj['hazardCodes'].push(res);
            }
            return obj;
          },
          {
            highlightedInformation: [],
            hazardCodes: []
          }
        )
      : null;

  return highlightedInformation;
};

export const categoryShowCode = {
  fruit: '03',
  meat: '07',
  charcuterie: '06'
};

export const showLanguage = (bfCategoryCode?: string | null) =>
  bfCategoryCode
    ? Object.values(categoryShowCode).some(code =>
        bfCategoryCode.startsWith(code)
      )
    : false;

export const getProductDescription = (product: ILoop54Product) => {
  const { descriptiveSize, countryOfOrigin, brand, bfCategoryCode } = product;
  let finalString = '';
  if (brand && brand !== '.') {
    finalString += brand + ', ';
  }

  finalString += descriptiveSize;
  const shouldShowLanguage = showLanguage(bfCategoryCode);
  if (countryOfOrigin && shouldShowLanguage) {
    finalString += ', ' + capitalizeWord(countryOfOrigin);
  }
  return finalString;
};

export const exludeItemFromItemArrayByProductId = (
  items: Product[],
  productId: string
) => {
  return items.filter(x => x.id !== productId);
};
