import React from 'react';
import { getProductDescription } from '../../../lib/product';
import { IExtendedProduct } from '../../../types/storefront/product';

interface IMeta {
  product: IExtendedProduct;
}

const Meta = ({ product }: IMeta) => (
  <div className="meta">
    <h1>{product.name}</h1>
    <div className="subname">{getProductDescription(product)}</div>
  </div>
);

export default Meta;
