import React from 'react';
import { IExtendedProduct } from '../../../types/storefront/product';

type TProductPricePromotionsDisclaimer = {
  product: IExtendedProduct;
};
const ProductPricePromotionsDisclaimer = ({
  product
}: TProductPricePromotionsDisclaimer) => {
  const promotions = product.productStoreDetails?.prices?.promotions;
  return (
    <>
      {promotions &&
        (parseInt(promotions[0]?.type || '0') === 1 ||
          parseInt(promotions[0]?.type || '0') === 5) &&
        promotions[0]?.minAmount && (
          <div className={'shop__disclaimer'}>
            <p>
              *Gäller ej spel, tidningar, tobak, läkemedel, presentkort, pant
              och alkoholhaltiga drycker eller i samband med andra rabatter.
            </p>
          </div>
        )}
    </>
  );
};

export default ProductPricePromotionsDisclaimer;
