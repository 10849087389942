import { format } from 'date-fns';
import React from 'react';
import { IExtendedProduct } from '../../../types/storefront/product';

type TProductOfferEndDate = {
  product: IExtendedProduct;
};

const ProductOfferEndDate = ({ product }: TProductOfferEndDate) => {
  const promotions = product.productStoreDetails?.prices?.promotions;
  const handleShowEndDate = promotions => {
    const date = new Date();
    const fromDate = new Date(promotions.from);
    const toDate = new Date(promotions.to);

    if (date < fromDate) {
      return null;
    }

    if (date >= fromDate && date <= toDate) {
      return `Erbjudandet gäller ${format(fromDate, 'd/M')} - ${format(
        toDate,
        'd/M'
      )}`;
    }

    return null;
  };

  return (
    <div>
      {product && promotions?.[0] && (
        <span className="c-productdetails__offer-details">
          {handleShowEndDate(promotions[0])}
        </span>
      )}
    </div>
  );
};

export default ProductOfferEndDate;
