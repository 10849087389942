import { CartCard, CartCardLabel } from '@citygross/components';
import { BodyText } from '@citygross/typography';
import React from 'react';
import { useTheme } from 'styled-components';
import { formatPriceWithDecimalsReduced } from '../../../lib/price';
import { useDispatch } from 'react-redux';
import { removeRecipeFromCart } from '../../../actions/cart';
import { translateRecipeMarkings } from '../../../lib/recipe';
import cs from 'classnames';
import { setImage } from '../../../lib/product';
import { Image } from '../../../types/xim/product';
import { IMAGE_PATHS } from '../../../constants/imagePaths';
export type TRecipeCartItem = {
  name: string;
  quantity: string;
  unit: string;
  image: {
    url: string;
    alt: string;
  };
  recipeId: string;
  totalPrice: number;
  recipeLabels: string[];
  isLast: boolean;
  images: Image[];
};
const mapRecipeLabelToColor = (label: string) => {
  switch (label) {
    case 'organic':
      return '#65DB69';
    case 'glutenFree':
      return '#D5642A';
    case 'lactoseFree':
      return '#8CCDF0';
    default:
      return '#65DB69';
  }
};

export const RecipeCartItem = (item: TRecipeCartItem) => {
  const { name, totalPrice, unit, quantity, recipeLabels } = item;
  const theme = useTheme();
  const formattedPrice = formatPriceWithDecimalsReduced(totalPrice);
  const dispatch = useDispatch();
  const image = setImage(item?.images, 0, IMAGE_PATHS.RECIPES);
  return (
    <div
      className={cs('cart-card', {
        ['cart-card__last-card']: item?.isLast
      })}
    >
      <CartCard
        title={name}
        image={image?.url + '?w=80&h=80'}
        titleLineClamp={2}
        markings={
          recipeLabels.length ? (
            <div className="recipe-labels">
              {recipeLabels.map((label, index) => (
                <CartCardLabel
                  key={index}
                  label={translateRecipeMarkings(label)}
                  background={mapRecipeLabelToColor(label)}
                />
              ))}
            </div>
          ) : null
        }
        description={
          <div>
            <p className="cart-card__description">
              {quantity} {unit}
            </p>
          </div>
        }
        price={
          <BodyText color={theme.palette?.darkest} fontWeight="medium">
            {formattedPrice?.value},{formattedPrice?.decimal} kr
          </BodyText>
        }
        showDelete
        onDelete={() => {
          dispatch(removeRecipeFromCart(item));
        }}
      />
    </div>
  );
};
