import React from 'react';
import { PurchaseRule } from '../../../components/PurchaseRule';
import { IExtendedProduct } from '../../../types/storefront/product';
import { PriceRule } from '../../../lib/price';

type TProductPricePromotions = {
  product: IExtendedProduct;
  formattedPrice: PriceRule | null;
};

const ProductPricePromotions = ({
  product,
  formattedPrice
}: TProductPricePromotions) => {
  const promotions = product.productStoreDetails?.prices?.promotions;
  return (
    <>
      {promotions &&
      promotions[0]?.maxAppliedPerReceipt &&
      formattedPrice?.validPromotion &&
      Number(promotions[0].maxAppliedPerReceipt) > 0 ? (
        <PurchaseRule>
          {`Max ${promotions[0].maxAppliedPerReceipt} ${
            product.unit === 'kg' ? 'kg' : 'köp'
          } per kund`}
        </PurchaseRule>
      ) : null}
    </>
  );
};

export default ProductPricePromotions;
