import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CenteredElement from '../../../components/CenterElement';
import { CenteredSpinner } from '../../../components/Spinner/Spinner';
import Select from '../../../components/Select';
import { Button, FavoriteButton } from '../../../components/Buttons';
import FilterButton from '../../../components/Buttons/FilterButton';
import {
  open as openModal,
  close as closeModal
} from '../../../actions/confirmModal';
import Sticker from '../../../components/Sticker/Sticker';
import Clock from '../../../components/assets/clock.svg';
import { determineBase } from '../../../lib/recipe';
import LogoLandscape from '../../../components/Header/assets/citygross-logotype.svg';
import './RecipeHead.scss';
import { currencyFormatSek } from '../../../lib/currencyFormatSek';
import { connect } from 'react-redux';
import ChooseStoreButton from '../../../components/BuyButton/ChooseStoreButton';
import LoginPromptButton from '../../../components/BuyButton/LoginPromptButton';

const SELECT_OPTS = unit => [
  { value: 1, label: `1 ${unit}` },
  { value: 2, label: `2 ${unit}` },
  { value: 3, label: `3 ${unit}` },
  { value: 4, label: `4 ${unit}` },
  { value: 5, label: `5 ${unit}` },
  { value: 6, label: `6 ${unit}` },
  { value: 7, label: `7 ${unit}` },
  { value: 8, label: `8 ${unit}` },
  { value: 9, label: `9 ${unit}` },
  { value: 10, label: `10 ${unit}` },
  { value: 11, label: `11 ${unit}` },
  { value: 12, label: `12 ${unit}` }
];

export class RecipeHead extends PureComponent {
  constructor(props) {
    super(props);

    const baseVariant = determineBase(props.variants);

    this.state = {
      baseVariant,
      validVariants: props.variants,
      existingVariants: props.variants.filter(
        variant =>
          variant?.prices !== undefined && variant?.prices?.length !== 0
      ),
      selectedNumberOfMeals: null,
      activePrice: null
    };
  }

  render() {
    const {
      adding,
      addToCartEnabled,
      cookingTime,
      defaultSelectedTags,
      favorite,
      id,
      image,
      isFavoriteLoading,
      onClick,
      handleTagToggle,
      name,
      onPortionChange,
      price,
      selectedArticlePrice,
      portions,
      removeFavorite,
      setRecipeFavorite,
      selectedTags,
      sellable,
      source,
      success,
      tags,
      isValid,
      quantity,
      loadingIngredientsFailed,
      storeId,
      toggleAssortmentPicker,
      deliveryOptions,
      weightWatchersGreenPoints,
      weightWatchersBluePoints,
      weightWatchersPurplePoints,
      weightWatchersPoints,
      weightWatchersLink,
      isAuthenticated
    } = this.props;

    let stickers = [];
    if (
      defaultSelectedTags &&
      defaultSelectedTags.some(x => x === 'vegetarian')
    ) {
      stickers = stickers.concat({
        altText: 'Vegetariskt alternativ',
        path: require('../../../components/assets/veg.svg'), //'site-symbols/veg.svg',
        imageWidth: 76,
        imageHeight: 76
      });
    }

    if (defaultSelectedTags && defaultSelectedTags.some(x => x === 'vegan')) {
      stickers = stickers.concat({
        altText: 'Veganskt alternativ',
        path: require('../../../components/assets/vegan.svg'), //'site-symbols/veg.svg',
        imageWidth: 76,
        imageHeight: 76
      });
    }

    return (
      <Fragment>
        <div className="c-recipe-head">
          <CenteredElement>
            <div className="logo only-for-print" id="logo">
              <img alt="City Gross" height="32" src={LogoLandscape} />
            </div>
            <div className="image only-for-print">
              <img alt="recipe" width="400" src={image} />
            </div>
            <div className="c-recipe-head__meta">
              <div>{source}</div>

              <div className="time">
                <img src={Clock} width="20" height="20" alt="" />
                <span className="text">{cookingTime} min</span>
              </div>
            </div>
            <h1>{name}</h1>
            {weightWatchersPoints ? (
              <div className="c-recipe-head__smartpoints">
                <div
                  className={`c-recipe-head${
                    weightWatchersPoints.length === 1
                      ? '__singlepoint'
                      : '__personalpoint'
                  } wwpoints`}
                >
                  {weightWatchersPoints}
                </div>

                <div
                  className="c-recipe-head__smartpointaction"
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    this.props.openModal({
                      title: (
                        <div style={{ textAlign: 'left' }}>
                          PersonligaPoints
                        </div>
                      ),
                      body: (
                        <div style={{ textAlign: 'left' }}>
                          {sellable && weightWatchersLink ? (
                            <p>
                              Pointsintervallet visar hur många Points receptet
                              kan ha. För att se ditt personliga värde,
                              <a
                                rel="noopener noreferrer nofollow"
                                target="_blank"
                                href={weightWatchersLink}
                                className="c-recipe-head__link"
                                onClick={() => {
                                  this.props.closeModal();
                                }}
                              >
                                klicka här
                              </a>
                            </p>
                          ) : (
                            <p>
                              I recepthäftet som medföljer matkassen finns en
                              QR-kod på framsidan. Scanna den för att få ditt
                              PersonligaPoints-värde på recepten.
                            </p>
                          )}
                        </div>
                      ),
                      confirmLabel: 'Stäng',
                      cancelLabel: null,
                      onConfirmClick: () => {}
                    })
                  }
                >
                  <p className="c-recipe-head__personalpoint-text">
                    {weightWatchersLink && sellable
                      ? 'Klicka för att få ditt PersonligaPoints-värde'
                      : 'PersonligaPoints per portion'}
                  </p>
                </div>
              </div>
            ) : (
              // @TODO: remove when fully implemented new personal points for all items
              (weightWatchersPurplePoints ||
                weightWatchersGreenPoints ||
                weightWatchersBluePoints) && (
                <div className="c-recipe-head__smartpoints">
                  {weightWatchersGreenPoints && (
                    <div
                      className="c-recipe-head__smartpoint"
                      style={{ background: '#08514a' }}
                    >
                      {weightWatchersGreenPoints}
                    </div>
                  )}
                  {weightWatchersBluePoints && (
                    <div
                      className="c-recipe-head__smartpoint"
                      style={{ background: '#0073d6' }}
                    >
                      {weightWatchersBluePoints}
                    </div>
                  )}
                  {weightWatchersPurplePoints && (
                    <div
                      className="c-recipe-head__smartpoint"
                      style={{ background: '#7a1d5b' }}
                    >
                      {weightWatchersPurplePoints}
                    </div>
                  )}
                  <div
                    className="c-recipe-head__smartpointaction"
                    onClick={() =>
                      this.props.openModal({
                        title: (
                          <div style={{ textAlign: 'left' }}>SmartPoints</div>
                        ),
                        body: (
                          <div style={{ textAlign: 'left' }}>
                            De runda ikonerna visar SmartPointsvärdet för de
                            olika planerna som du kan bli matchad med enligt WW
                            ViktVäktarnas program mittWW+.
                          </div>
                        ),
                        confirmLabel: 'Stäng',
                        cancelLabel: null,
                        onConfirmClick: () => {}
                      })
                    }
                  >
                    SmartPoints per portion
                  </div>
                </div>
              )
            )}
          </CenteredElement>
        </div>
        <div className={'c-recipe-head c-recipe-head-actions'}>
          <CenteredElement>
            <div
              className={'c-recipe-head-actions__align-filters-and-add-to-cart'}
            >
              <div
                className={'c-recipe-head-actions__portions-and-customisation'}
              >
                <div className={'c-recipe-head-actions__portions'}>
                  {quantity.value > 12 ? (
                    <Select
                      defaultValue={quantity.value}
                      onChange={e => onPortionChange(e)}
                      options={[
                        {
                          value: quantity.value,
                          label: `${quantity.value} ${quantity.unit}`
                        }
                      ]}
                    />
                  ) : (
                    <>
                      <p>Välj antal portioner:</p>
                      <Select
                        defaultValue={portions}
                        onChange={e => onPortionChange(e)}
                        options={SELECT_OPTS(quantity.unit)}
                      />
                    </>
                  )}
                  <div className="only-for-print">
                    {portions} {quantity.unit}
                  </div>
                </div>
                <div className={'c-recipe-head-actions__customisation'}>
                  {sellable && tags.length > 0 && (
                    <div className="c-recipe-head__tags-button">
                      <p>Anpassa ingredienserna i receptet:</p>
                      <span className="c-recipe-head__tags-button__button-wrapper">
                        {tags.map(({ label, value, color }, index) => (
                          <Fragment key={`with-color-key-${index}`}>
                            <FilterButton
                              active={
                                selectedTags.some(x => x === value) ||
                                defaultSelectedTags.some(x => x === value)
                              }
                              onClick={() =>
                                handleTagToggle(
                                  value,
                                  defaultSelectedTags.some(x => x === value)
                                )
                              }
                              buttonType={'filter'}
                              color={color}
                              className={label.toLowerCase()}
                            >
                              {label}
                            </FilterButton>
                          </Fragment>
                        ))}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!loadingIngredientsFailed && (
                <div className={'c-recipe-head-actions__shop full-width-xs'}>
                  <div className="c-recipe-head-actions__shop-inner-wrapper">
                    {sellable &&
                      (price || selectedArticlePrice > 0) &&
                      storeId && (
                        <div className="price h4 u-text-right">
                          {selectedArticlePrice === 0 ? (
                            <CenteredSpinner />
                          ) : (
                            currencyFormatSek(selectedArticlePrice)
                          )}
                        </div>
                      )}
                    <div className="c-recipe-head__button full-width-xs">
                      {((sellable &&
                        (Boolean(price) ||
                          Boolean(selectedArticlePrice > 0))) ||
                        storeId === null) &&
                        (!storeId ? (
                          <ChooseStoreButton minWidth={190} />
                        ) : !isAuthenticated ? (
                          <LoginPromptButton minWidth={190} />
                        ) : (
                          <Button
                            buttonType={'primary'}
                            onClick={
                              !storeId ||
                              !deliveryOptions.canAddProductsOrRecipes
                                ? () => toggleAssortmentPicker(true)
                                : onClick
                            }
                            disabled={(addToCartEnabled && storeId) || !isValid}
                            loading={adding}
                            success={success}
                            primary
                            className={'full-width-xs safari-button-height'}
                            minWidth={190}
                          >
                            {isValid ? (
                              <>
                                {!deliveryOptions.canAddProductsOrRecipes
                                  ? 'Byt leveranssätt'
                                  : storeId
                                  ? 'Lägg i varukorgen'
                                  : 'Välj butik'}
                              </>
                            ) : (
                              'Inte fullständigt'
                            )}
                          </Button>
                        ))}
                      {!storeId && (
                        <Link to={'/kundservice'}>Mer info & villkor</Link>
                      )}
                      <FavoriteButton
                        active={favorite}
                        loading={isFavoriteLoading}
                        handleClick={
                          favorite
                            ? () => removeFavorite(favorite)
                            : () => setRecipeFavorite(id, name)
                        }
                      />
                      <Sticker
                        sources={stickers}
                        alignment={{
                          top: 10,
                          right: 10
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </CenteredElement>
        </div>
      </Fragment>
    );
  }
}

RecipeHead.defaultProps = {
  favorite: false,
  tags: []
};

const mapDispatchToProps = {
  openModal,
  closeModal
};

export default connect(null, mapDispatchToProps)(RecipeHead);
