export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

const SORT_DESC = '_desc';

export interface ILoopSort {
  value: string;
  order: string;
  label: string;
}

export const PRODUCT_SORT_OPTS = [
  {
    value: '',
    order: SORT_ORDER.ASC,
    label: 'Popularitet'
  },
  {
    value: 'p_name',
    order: SORT_ORDER.ASC,
    label: 'Namn A till Ö'
  },
  {
    value: 'p_name_desc',
    order: SORT_ORDER.DESC,
    label: 'Namn Ö till A'
  },
  {
    value: 'p_price',
    order: SORT_ORDER.ASC,
    label: 'Lägsta pris'
  },
  {
    value: 'p_price_desc',
    order: SORT_ORDER.DESC,
    label: 'Högsta pris'
  },
  {
    value: 'p_comparison_price',
    order: SORT_ORDER.ASC,
    label: 'Jämförelsepris'
  },
  {
    value: 'p_brand',
    order: SORT_ORDER.ASC,
    label: 'Varumärke A-Ö'
  },
  {
    value: 'p_brand_desc',
    order: SORT_ORDER.DESC,
    label: 'Varumärke Ö-A'
  },
  {
    value: 'p_bfcategorycode',
    order: SORT_ORDER.ASC,
    label: 'Varugrupp'
  }
];

export const RECIPE_SORT_OPTS = [
  {
    value: '',
    order: SORT_ORDER.ASC,
    label: 'Popularitet'
  },
  {
    value: 'p_name',
    order: SORT_ORDER.ASC,
    label: 'Namn A till Ö'
  },
  {
    value: 'p_name_desc',
    order: SORT_ORDER.DESC,
    label: 'Namn Ö till A'
  },
  {
    value: 'p_price',
    order: SORT_ORDER.ASC,
    label: 'Lägsta pris'
  },
  {
    value: 'p_price_desc',
    order: SORT_ORDER.DESC,
    label: 'Högsta pris'
  }
];

export const sortParamsHelper = (
  value: string,
  options: typeof PRODUCT_SORT_OPTS
) => {
  const order = options.find(option => option.value === value)?.order;
  const val = value.replace(SORT_DESC, '');
  return {
    SortField: val,
    SortOrder: order,
    page: 0
  };
};
