import React from 'react';
import cs from 'classnames';
import Image from '../../Image';
import BuyButton from '../../BuyButton';
import './ProductTableItem.scss';
import { Link } from 'react-router-dom';
import { getProductDescription, setImage } from '../../../lib/product';
import { currencyFormatSek } from '../../../lib/currencyFormatSek';
import { IExtendedProduct } from '../../../types/storefront/product';

interface IProductTableItems {
  product: IExtendedProduct;
  noLazy: boolean;
  addToCart: Function;
  increaseItemQuantity: Function;
  decreaseItemQuantity: Function;
  onProductTableItemClick: Function;
}
const ProductTableItem: React.FC<IProductTableItems> = ({
  product,
  onProductTableItemClick,
  noLazy = true
}) => {
  const { id, gtin, images, name, productStoreDetails, url } = product;
  const price = productStoreDetails?.prices;
  const image = setImage(images, 0);
  const unitPrice = price && (
    <div
      className={cs('c-producttable-product__unitprice', 'u-text-right', {
        extraprice: price.ordinaryPrice.price
      })}
    >
      <span className="fieldlabel u-screen-xs-hide u-screen-sm-hide u-screen-md-hide u-screen-lg-hide">
        Pris/st:
      </span>
      <span className="fieldvalue">
        {price.ordinaryPrice.price
          ? currencyFormatSek(price.ordinaryPrice.price)
          : NaN}
      </span>
    </div>
  );

  return (
    <div className={cs('c-producttable-product')} data-productid={gtin}>
      <Link
        to={{
          pathname: url,
          state: { flyout: true, title: ' ', nopadding: true, tight: false }
        }}
        className={
          'c-producttable-product__link-wrapper c-producttable-product__row '
        }
        onClick={() => onProductTableItemClick()}
      >
        <div className={'c-producttable-product__row'}>
          <div className="c-producttable-product__image table-column-xs-sm-4-24 table-column-md-lg-3-24 u-text-center">
            <Image
              noLazy={noLazy}
              src={image?.url + '?w=70&h=70'}
              alt={image.alt}
              className={'u-img-responsive'}
            />
          </div>
          <div
            className={
              'c-producttable-product__title table-column-xs-sm-12-24 table-column-md-lg-9-24'
            }
          >
            <div className="name">{name}</div>
            <div className="description">{getProductDescription(product)}</div>
          </div>
          <div
            className={
              'c-producttable-product__unit-price table-column-xs-sm-6-24 table-column-md-lg-7-24 u-text-right'
            }
          >
            {unitPrice}
          </div>

          <div
            className={
              'c-producttable-product__droparrow table-column-xs-sm-2-24 u-screen-md-hide u-screen-lg-hide '
            }
          />
          <div
            className={cs(
              'c-producttable-product__qty',
              'in-dropdown',
              'table-column-md-lg-5-24',
              'u-screen-xs-hide',
              'u-screen-sm-hide'
            )}
          >
            <BuyButton product={product} />
          </div>
        </div>

        <div className={'c-producttable-product__row dropdown-wrapper'}>
          <div
            className={cs(
              'c-producttable-product__qty',
              'in-dropdown',
              'table-column-xs-sm-24-24',
              'u-screen-md-hide',
              'u-screen-lg-hide'
            )}
          >
            <BuyButton product={product} />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductTableItem;
