import React, { useEffect, useMemo, useState } from 'react';
import cs from 'classnames';
import ProductView from './ProductView';
import RecipeView from './RecipeView';
import ContentView from './ContentView';
import qs from 'query-string';
import { ToggleButton } from '../../../components/Buttons';

import './SearchResultPage.scss';
import CategoryFilter from '../../../components/CategoryFilter';
import {
  ExtendedSearchResult,
  TSearchFilterOption
} from '../../../types/search/searchResult';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentSearch,
  selectNewSearchResultLoading,
  setOpenSearch
} from '../../../slices/searchSlice';
import { BodyText } from '@citygross/typography';
import { Button, Spacer } from '@citygross/components';
import SearchMetaCompletions from '../../../modules/Search/SearchMetaCompletions';
import { SEARCH_TYPES } from '../../../lib/searchSlice';
import { LOOP_FACETS } from '../../../types/loop54Facets';
import { PRODUCT_SORT_OPTS, RECIPE_SORT_OPTS } from '../../../lib/sorting';

const navList = {
  product: { order: 0, translation: 'Matvaror' },
  cateredmeal: { order: 1, translation: 'Catering' },
  recipe: { order: 2, translation: 'Recept' },
  category: { order: 3, translation: 'Kategorier' },
  store: { order: 4, translation: 'Butiker' },
  page: { order: 5, translation: 'Sidor' }
};

const initialOptionalFilters = {
  cookingtime: '',
  brand: '',
  marking: ''
};

type TNavListItems = {
  id: string;
  option: string;
  count: string;
  selected: boolean;
  listOrder?: number;
  prettyName?: string;
};

type TSearchResultPage = {
  searchString: string;
  increaseItemQuantity?: any;
  decreaseItemQuantity?: any;
  redirectToLogin?: any;
  addToCart?: any;
  setProductFavorite?: any;
  setRecipeFavorite?: any;
  isAuthenticated?: any;
  removeFavorite?: any;
  storeId?: number;
  fetchExtendedSearch?: any;
  fetchPaginatedSearch?: any;
  newSearch: ExtendedSearchResult;
  location: Location;
  categoryId: number;
  impressionListName: string;
};

const SearchResultPage = ({
  searchString,
  increaseItemQuantity,
  decreaseItemQuantity,
  redirectToLogin,
  addToCart,
  setProductFavorite,
  setRecipeFavorite,
  isAuthenticated,
  removeFavorite,
  storeId,
  fetchExtendedSearch,
  fetchPaginatedSearch,
  newSearch,
  location,
  categoryId,
  impressionListName
}: TSearchResultPage) => {
  const {
    product,
    recipe,
    cateredmeal,
    page,
    store,
    category,
    pagination,
    loading,
    activeFilter,
    filters
  } = newSearch;
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState<TSearchFilterOption[]>(
    []
  );
  const query = qs.parse(location.search);
  const currentSearch = useSelector(selectCurrentSearch);
  const searchLoading = useSelector(selectNewSearchResultLoading);
  const [navState, setNavState] = useState<TNavListItems[]>();

  const makeFormattedNavList = filters => {
    const searchTypes = filters?.find(f => f.name === 'Type');
    const newNavItems = searchTypes?.options;
    let formattedNavItems: (TNavListItems & { listOrder: number })[] = [];
    if (newNavItems) {
      newNavItems?.forEach(item =>
        formattedNavItems.push({
          ...item,
          count: item.count.toString(),
          listOrder: navList[item.option]?.order,
          prettyName: navList[item.option]?.translation
        })
      );
      formattedNavItems.sort((a, b) => a?.listOrder - b?.listOrder);
    }
    return formattedNavItems;
  };
  useEffect(() => {
    setNavState(makeFormattedNavList(pagination?.filters));
    if (
      filters &&
      JSON.stringify(navState) !==
        JSON.stringify(makeFormattedNavList(pagination?.filters))
    ) {
      const initFilters: TSearchFilterOption[] = [];
      Object.keys(filters)?.forEach(key => {
        if (LOOP_FACETS[key]) {
          filters?.[key]?.options?.forEach(filter => {
            if (filter.selected) {
              initFilters.push({ ...filter, parent: key });
            }
          });
        }
      });
      setSelectedFilters(initFilters);
    }
  }, [currentSearch]);

  const filterString = (filters: TSearchFilterOption[], type: string) =>
    filters
      ?.filter(filterType => filterType.parent === type)
      ?.map(filter => filter.option)
      ?.join(',');

  const getFilterParams = newFilters => {
    const chosenFilters = {};
    Object.entries(LOOP_FACETS).forEach(
      ([key, val]) => (chosenFilters[key] = filterString(newFilters, val))
    );
    return chosenFilters;
  };
  const filterAction = (filter: TSearchFilterOption) => {
    let newFilters = [...selectedFilters];
    const [keyValue] = Object.entries(filter);
    const [filterKey, filterValue] = keyValue;

    const filterType = filters?.find(filter => filter.name === filterKey);
    const validFilter = filterType?.options?.find(
      id => id.option === filterValue
    );
    if (validFilter) {
      const filterClicked: TSearchFilterOption = {
        ...validFilter,
        parent: filterKey
      };
      const filterSelected = selectedFilters.find(
        selected => selected.option === filterClicked.option
      );
      if (filterSelected) {
        newFilters = newFilters.filter(
          fil => fil.option !== filterClicked.option
        );
      } else {
        newFilters.push(filterClicked);
      }
    }
    setSelectedFilters(newFilters);
    const chosenFilters = getFilterParams(newFilters);

    if (pagination.sort) {
      chosenFilters['SortField'] = pagination.sort;
    }
    fetchExtendedSearch({
      location,
      ...query,
      page: 0,
      store: storeId,
      ...chosenFilters,
      type: activeFilter
    });
  };
  const hasSuggestions =
    pagination?.completions && Boolean(pagination.completions.length);

  const sortOptions = useMemo(() => {
    if (activeFilter === SEARCH_TYPES.product) {
      return PRODUCT_SORT_OPTS;
    }
    if (activeFilter === SEARCH_TYPES.recipe) {
      return RECIPE_SORT_OPTS;
    }
    return [PRODUCT_SORT_OPTS[0]];
  }, [activeFilter]);

  return (
    <div className="m-searchresultpage">
      <div className="m-searchresultpage__sidebar">
        {navState &&
          navState?.map((filter, index) => {
            if (Number(filter?.count) > 0) {
              return (
                <div
                  key={`${filter.count}-${index}`}
                  className={cs('m-searchresultpage__navitem', {
                    active: filter.option === activeFilter
                  })}
                  onClick={() => {
                    setSelectedFilters([]);
                    fetchExtendedSearch({
                      location: location,
                      ...query,
                      page: 0,
                      store: storeId,
                      type: filter.option,
                      ...initialOptionalFilters
                    });
                  }}
                >
                  {' '}
                  {filter.prettyName} <span>({filter.count})</span>{' '}
                </div>
              );
            }
          })}
      </div>
      <div className="m-searchresultpage__content">
        <h1>Sökresultat för {`"${searchString}"`}</h1>
        {hasSuggestions ? (
          <div style={{ maxWidth: 'calc(100vw - 20px)' }}>
            {/* @TODO add corrections?*/}
            <SearchMetaCompletions completions={pagination.completions} />
          </div>
        ) : null}

        <CategoryFilter
          id={''}
          filters={
            activeFilter === SEARCH_TYPES.product ||
            activeFilter === SEARCH_TYPES.recipe
              ? filters
              : []
          }
          selectedFilters={selectedFilters}
          sortProducts={(id, params) => {
            document.cookie = `sortArticles=${params.SortField};path=/`;
            const chosenFilters = getFilterParams(selectedFilters);
            fetchExtendedSearch({
              location: location,
              ...query,
              ...chosenFilters,
              type: activeFilter,
              page: 0,
              store: storeId,
              SortField: params?.SortField,
              SortOrder: params?.SortOrder
            });
          }}
          filterAction={filterAction}
          pagination={pagination}
          sortOptions={sortOptions}
        />

        <div className="m-searchresultpage__mobilenav">
          {navState &&
            navState?.map((filter, i) => {
              if (Number(filter.count) > 0) {
                return (
                  <ToggleButton
                    key={i}
                    active={filter.id === activeFilter}
                    onClick={() => {
                      setSelectedFilters([]);
                      fetchExtendedSearch({
                        location: location,
                        ...query,
                        page: 0,
                        store: storeId,
                        type: filter.option,
                        ...initialOptionalFilters
                      });
                    }}
                  >
                    {' '}
                    {filter.prettyName} ({filter.count}){' '}
                  </ToggleButton>
                );
              }
            })}
        </div>

        {!activeFilter && !searchLoading && (
          <div className="search-no_found__container">
            <BodyText textAlign="center">Inga resultat hittades</BodyText>
            <Spacer />
            <BodyText textAlign="center">
              testa en annan sökning eller rensa filter
            </BodyText>
            <Spacer />
            <div className="search-no_found__buttons">
              <Button
                size="medium"
                color={'secondary'}
                onClick={() => dispatch(setOpenSearch(true))}
              >
                <BodyText color="#fff">Sök</BodyText>
              </Button>
              <Button
                size="medium"
                color={'secondary'}
                onClick={() => {
                  setSelectedFilters([]);
                  fetchExtendedSearch({
                    location,
                    ...query,
                    page: 0,
                    store: storeId,
                    type: SEARCH_TYPES.product,
                    ...initialOptionalFilters
                  });
                }}
              >
                <BodyText color="#fff">Rensa filter</BodyText>
              </Button>
            </div>
          </div>
        )}
        {activeFilter === SEARCH_TYPES.product && (
          <ProductView
            items={product}
            pagination={newSearch?.pagination}
            requestMoreProducts={page => {
              fetchPaginatedSearch({
                store: storeId,
                ...query,
                type: SEARCH_TYPES.product,
                page,
                location
              });
            }}
            increaseItemQuantity={increaseItemQuantity}
            decreaseItemQuantity={decreaseItemQuantity}
            redirectToLogin={redirectToLogin}
            isAuthenticated={isAuthenticated}
            fetching={loading}
            addToCart={addToCart}
            setProductFavorite={setProductFavorite}
            removeFavorite={removeFavorite}
          />
        )}
        {activeFilter === SEARCH_TYPES.catered_meals && (
          <ContentView
            items={cateredmeal?.items}
            pagination={newSearch?.pagination}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                page,
                store: storeId,
                type: SEARCH_TYPES.catered_meals,
                location
              });
            }}
          />
        )}
        {activeFilter === SEARCH_TYPES.recipe && (
          <RecipeView
            items={recipe}
            pagination={newSearch?.pagination}
            requestMoreRecipes={(_, params) => {
              fetchPaginatedSearch({
                ...query,
                store: storeId,
                type: SEARCH_TYPES.recipe,
                page: params.page,
                location
              });
            }}
            fetching={loading}
            isAuthenticated={isAuthenticated}
            setRecipeFavorite={setRecipeFavorite}
            removeFavorite={removeFavorite}
            redirectToLogin={redirectToLogin}
            categoryId={categoryId}
            impressionListName={impressionListName}
          />
        )}
        {activeFilter === SEARCH_TYPES.category && (
          <ContentView
            items={category.items}
            pagination={newSearch?.pagination}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                page,
                store: storeId,
                type: 'category',
                location
              });
            }}
          />
        )}
        {activeFilter === SEARCH_TYPES.store && (
          <>
            <ContentView
              items={store?.items}
              pagination={newSearch?.pagination}
              requestMoreContent={page => {
                fetchPaginatedSearch({
                  page: page,
                  store: storeId,
                  type: 'store',
                  location
                });
              }}
            />
          </>
        )}
        {activeFilter === SEARCH_TYPES.page && (
          <ContentView
            items={page}
            pagination={newSearch?.pagination}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                page: page,
                store: storeId,
                type: SEARCH_TYPES.page,
                location
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResultPage;
