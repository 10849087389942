import { comparisonTypes } from '@citygross/utils';
import React from 'react';
import { formatPriceWithDecimalsReduced, PriceRule } from '../../../lib/price';
import { IExtendedProduct } from '../../../types/storefront/product';

type TProductPrices = {
  product: IExtendedProduct;
  formattedPrice: PriceRule | null;
};
const ProductPrices = ({ product, formattedPrice }: TProductPrices) => {
  const prices = product.productStoreDetails?.prices;

  const hasReturnablePackageDeposit =
    product.returnablePackageDepositInformation &&
    product.returnablePackageDepositInformation.description === 'Pant';

  return (
    <>
      {prices?.hasDiscount && formattedPrice?.validPromotion && (
        <p className={'bold ordinal-price mb-5'}>
          {`Ord. pris ${formattedPrice?.formattedOrdinaryPrice?.value +
            ',' +
            formattedPrice?.formattedOrdinaryPrice?.decimal} kr${
            hasReturnablePackageDeposit ? product.unit : `/${product.unit}`
          }`}
        </p>
      )}
      {prices?.hasDiscount &&
        formattedPrice?.validPromotion &&
        formattedPrice?.ordinaryPrice?.comparativePrice &&
        formattedPrice?.ordinaryPrice?.comparativePrice > 0 && (
          <p className={'grey ordinal-compare-price mb-5'}>
            {`${'Ord. jfr pris'} ${
              formattedPrice.ordinaryComparisonPrice
            } kr/${formattedPrice?.ordinaryPrice?.comparativePriceUnit &&
              comparisonTypes(
                formattedPrice?.ordinaryPrice?.comparativePriceUnit
              )}`}
          </p>
        )}

      {formattedPrice?.currentPrice?.comparativePrice &&
        formattedPrice?.currentPrice?.comparativePrice > 0 && (
          <span className={'grey compare-price'}>
            {`${'Jfr pris'} ${
              formatPriceWithDecimalsReduced(
                formattedPrice?.currentPrice?.comparativePrice
              )?.fullValue
            } kr/${formattedPrice?.currentPrice?.comparativePriceUnit &&
              comparisonTypes(
                formattedPrice?.currentPrice?.comparativePriceUnit
              )}`}
          </span>
        )}
      {prices?.displayLowestPriceLast30Days &&
        prices?.lowestPriceLast30Days != null && (
          <div className="mt-5">
            <span>
              Tidigare lägsta pris{' '}
              {
                formatPriceWithDecimalsReduced(prices?.lowestPriceLast30Days)
                  ?.fullValue
              }
              kr/
              {comparisonTypes(prices?.ordinaryPrice.unit ?? '')}
            </span>
          </div>
        )}
    </>
  );
};

export default ProductPrices;
