import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProductById } from '../../actions/product';
import { authenticatedSelector } from '../../selectors/user';
import ProductSingle from '../../modules/ProductSingle/ProductSingle';
import LoadBlock from '../../components/LoadingBlock';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { allProductsWithDefaults } from '../../selectors/product/productSelectors';
import { IExtendedProduct } from '../../types/storefront/product';
import { AppState } from '../../reducers';

interface IProductSinglePageProps {
  isFlyout: boolean;
}

const ProductSinglePage: React.FC<IProductSinglePageProps> = ({ isFlyout }) => {
  const { productId } = useParams<{ productId: string }>();
  const dispatch = useDispatch();
  const [localStore, setLocalStore] = useState<number | null>(null);
  const storeId = useSelector(selectCurrentAssortment);
  const isFetching = useSelector((state: AppState) => state.product.fetching);
  const isAuthenticated = useSelector(authenticatedSelector);
  const product = useSelector((state: AppState) =>
    allProductsWithDefaults(state)?.find(
      (product: IExtendedProduct) =>
        product.id?.toLowerCase() === productId?.toLowerCase()
    )
  );

  const fetchProduct = useCallback(
    storeId => {
      if ((!product || storeId !== localStore) && !isFetching) {
        dispatch(getProductById(productId));
      }
      setLocalStore(storeId);
    },
    [product, isFetching, dispatch, productId, localStore]
  );

  useEffect(() => {
    fetchProduct(storeId);
  }, [productId, storeId, fetchProduct]);

  if (!product) {
    return <LoadBlock />;
  }

  return (
    <ProductSingle
      product={product}
      currentBreadCrumb={{ name: product.name, link: { url: product.url } }}
      storeId={storeId}
      isAuthenticated={isAuthenticated}
      isFlyout={isFlyout}
    />
  );
};

export default ProductSinglePage;
