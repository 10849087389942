import React from 'react';
import { Marking } from '../../../components/Marking';
import { IExtendedProduct } from '../../../types/storefront/product';

type TProductMarkings = {
  product: IExtendedProduct;
};

const ProductMarkings = ({ product }: TProductMarkings) => {
  return (
    <div className="markings">
      {product.mappedMarkings
        ?.slice(0, 3)
        .map(
          marking =>
            marking.image && (
              <Marking
                altText={marking.altText}
                code={marking.code}
                key={marking.id}
                imageUrl={marking.image.url}
                tooltipAlign={'left'}
              />
            )
        )}
    </div>
  );
};

export default ProductMarkings;
