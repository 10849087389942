import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Image from '../../../components/Image';
import { TOffer } from '../../../types/offers/offers';
import './OfferSingleDetails.scss';
import {
  AlertBox,
  Box,
  EListItemAlignment,
  ListItem,
  PriceTag,
  Spacer
} from '@citygross/components';
import { comparisonTypes } from '@citygross/utils';
import { useSelector } from 'react-redux';
import { selectCurrentAssortment } from '../../../selectors/assortments';
import ProductPlaceholder from '../../../components/assets/produkt-fallback.svg';
import {
  loopProductDefaults,
  sweetenedFiltersSelector,
  sweetenedSignalWordsSelector,
  sweetenedSymbolsSelector
} from '../../../selectors/product/productSelectors';
import { IExtendedProduct } from '../../../types/storefront/product';
import cs from 'classnames';
import {
  formatOfferAvailabilityDate,
  getOfferVariant
} from '../../../lib/offerInfo';
import { BodyText } from '@citygross/typography';
import { Icons } from '@citygross/icons';
import OfferProduct from '../../../components/OfferProduct';
import { fetchLoopProductById } from '../../../api/endpoints/product';
import { theme } from '@citygross/design-tokens';

type IOfferSingleDetails = {
  offer: TOffer;
  isFlyout: boolean;
};

export const OfferSingleDetails: React.FC<IOfferSingleDetails> = ({
  offer,
  isFlyout
}) => {
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState<IExtendedProduct[]>([]);
  const storeNumber = useSelector(selectCurrentAssortment);
  const sweetenedFilters = useSelector(sweetenedFiltersSelector);
  const sweetenedSignalWords = useSelector(sweetenedSignalWordsSelector);
  const sweetenedSymbols = useSelector(sweetenedSymbolsSelector);

  const fetchProducts = async (productIds: string[]) => {
    try {
      const products = await fetchLoopProductById(productIds);
      const extendedProducts = products?.data.items?.map(p =>
        loopProductDefaults(
          p,
          sweetenedFilters,
          sweetenedSignalWords,
          sweetenedSymbols
        )
      );

      setProducts(extendedProducts);
    } finally {
      setLoadingProducts(false);
    }
  };

  useEffect(() => {
    if (offer?.products.length) {
      const productIds = offer.products.map(({ id }) => id);
      if (storeNumber) {
        setLoadingProducts(true);
        fetchProducts(productIds);
      }
    }
  }, [offer.id, storeNumber]);

  const isValidForStore = useMemo(() => {
    return (
      storeNumber &&
      offer?.storeNumbers?.find(store => store === storeNumber?.toString())
    );
  }, [storeNumber, offer?.storeNumbers]);

  return (
    <div>
      {!storeNumber && (
        <Fragment>
          <AlertBox
            background={theme.palette?.yellowLighter}
            padding={16}
            borderColor={theme.palette?.brandYellow}
            icon={
              <Icons.Alert
                height={20}
                width={20}
                dynamicStrokeWidth
                viewBox="0 0 22 24"
              />
            }
          >
            <BodyText>För att se om erbjudandet gäller, välj butik</BodyText>
          </AlertBox>
          <Spacer lgSpacing={'md'} />
        </Fragment>
      )}

      {!isValidForStore && storeNumber && (
        <Fragment>
          <AlertBox
            background={theme.palette?.yellowLighter}
            padding={16}
            borderColor={theme.palette?.brandYellow}
            icon={
              <Icons.Alert
                height={20}
                width={20}
                dynamicStrokeWidth
                viewBox="0 0 22 24"
              />
            }
          >
            <BodyText>Erbjudandet gäller ej vald butik</BodyText>
          </AlertBox>

          <Spacer lgSpacing={'md'} />
        </Fragment>
      )}

      <div className="o-offerDetail">
        <div className="o-offerDetail__image-container">
          <Image src={offer.imageUrl} alt={offer.name} />
        </div>

        <div className="o-offerDetail__content">
          <div>
            <h2 className="o-offerDetail__title">{offer.name}</h2>

            {offer?.brand && (
              <p className="o-offerDetail__subTitle">
                {offer?.brand}
                {offer.weightVolume ? `, ${offer.weightVolume}` : ''}
              </p>
            )}
          </div>

          <div className="o-offerDetail__description">{offer.description}</div>

          <div>
            <PriceTag
              price={offer?.price}
              multiPrice={offer?.products?.[0]?.minQuantity}
              unit={
                comparisonTypes(offer?.products?.[0]?.priceDetails?.unit) || ''
              }
              priceVariant={getOfferVariant(offer)}
              pant={offer?.requiresReturnablePackageDeposit}
            />

            <div className="o-offerDetail__price-info">
              {offer?.regularRetailPrice && (
                <p className="o-offerDetail__text-small o-offerDetail__text-small-medium">
                  Ord pris. {offer.regularRetailPrice}
                </p>
              )}

              {offer.comparisonPrice && (
                <p className="o-offerDetail__text-small">
                  Jfr pris. {offer.comparisonPrice}
                </p>
              )}
            </div>
          </div>

          <Box
            padding={16}
            paddingVertical={12}
            rounded
            background={theme.palette?.blueLight}
          >
            {!!offer.maxAppliedPerReceipt && (
              <p className="o-offerDetail__text o-offerDetail__text-medium">
                Erbjudandet gäller max {offer.maxAppliedPerReceipt} köp per kund
              </p>
            )}

            {!!offer.minAmount && (
              <p className="o-offerDetail__text o-offerDetail__text-medium">
                Gäller vid köp av varor över {offer.minAmount} kr
              </p>
            )}

            {offer.from && offer.to && (
              <p className="o-offerDetail__text">
                Gäller {formatOfferAvailabilityDate(offer.from)} till{' '}
                {formatOfferAvailabilityDate(offer.to, true)}
              </p>
            )}
          </Box>
        </div>
      </div>

      {!!offer.products?.length && !!products.length && (
        <div className="o-offerProducts">
          {storeNumber && isValidForStore && (
            <h3
              className={cs({
                'o-offerProducts__title-no-flyout': !isFlyout,
                'o-offerProducts__title-flyout': isFlyout
              })}
            >
              Varor som ingår i erbjudandet
            </h3>
          )}

          {!storeNumber || !isValidForStore
            ? null
            : loadingProducts
            ? // eslint-disable-next-line react/display-name
              offer?.products?.map((p, i, arr) => (
                <div
                  key={p.id}
                  className={cs('o-offerProducts__offer-product', {
                    'o-offerProducts__offer-product-last': i === arr.length - 1
                  })}
                >
                  <ListItem
                    alignment={EListItemAlignment.TOP}
                    fallBackImage={ProductPlaceholder}
                    loading
                  />
                </div>
              ))
            : products.map((product, i, arr) => (
                <div
                  key={product.id}
                  className={cs('o-offerProducts__offer-product', {
                    'o-offerProducts__offer-product-last': i === arr.length - 1,
                    'o-offerProducts__offer-product-flyout': isFlyout
                  })}
                >
                  <OfferProduct
                    product={product}
                    displayLowestPriceLast30Days={
                      offer.displayLowestPriceLast30Days
                    }
                  />
                </div>
              ))}
        </div>
      )}
    </div>
  );
};
