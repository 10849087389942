import React, { Fragment } from 'react';
import { checkPromotionPrice } from '../../lib/price';
import cs from 'classnames';
import './PriceTag.scss';
import { IProductStoreDetails } from '../../types/loop54Product';
import { IExtendedProductPrice } from '../../types/storefront/product';
import { EPriceVariant, PriceTag as PriceTagComp } from '@citygross/components';

interface IPriceTag {
  forceLeftAlign?: boolean;
  price?: IProductStoreDetails | null;
  unit?: string;
  numberOfItems?: number;
  effectAmount?: number;
  isOnlyForMembers?: boolean;
  mixType?: string;
  plainPrice?: number;
  pant?: boolean;
  hasLimitedDeal?: boolean;
  isInSearch?: boolean;
}

const getSplash = (
  isOnlyForMembers,
  isExtraPrice,
  hasLimitedDeal,
  validPromotion
) => {
  if (isOnlyForMembers && isExtraPrice && !hasLimitedDeal) {
    return EPriceVariant.PRIO;
  } else if (isExtraPrice && hasLimitedDeal) {
    return EPriceVariant.KLIPP;
  } else if (validPromotion && isExtraPrice) {
    return EPriceVariant.REGULAR_OFFER;
  } else {
    return undefined;
  }
};
const PriceTag: React.FC<IPriceTag> = ({
  price,
  plainPrice,
  unit,
  pant,
  hasLimitedDeal,
  isInSearch
}) => {
  const {
    formattedPrice,
    isExtraPrice,
    isOnlyForMembers,
    isMix,
    mixMinQuantity,
    validPromotion
  } = checkPromotionPrice(price?.prices);

  return (formattedPrice && formattedPrice.value !== null) || plainPrice ? (
    <div
      className={cs('c-pricetag-grid', { ['c-pricetag-search']: isInSearch })}
    >
      <PriceTagComp
        price={Number(formattedPrice?.fullValue?.replace(',', '.'))}
        multiPrice={isMix ? Number(mixMinQuantity) : undefined}
        unit={pant ? 'st' : unit || ''}
        priceVariant={getSplash(
          isOnlyForMembers,
          isExtraPrice,
          hasLimitedDeal,
          validPromotion
        )}
        pant={pant}
        isInSmallContainer={isInSearch}
      />
    </div>
  ) : (
    <div className="value">&nbsp;</div>
  );
};

export default PriceTag;
