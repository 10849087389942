import { localStorage } from './document';
import config from '../config/config';
const { CATERING_STORAGE_KEY } = config;
import { setImage } from './product';
import groupby from 'lodash.groupby';
import { IMAGE_PATHS } from '../constants/imagePaths';

Array.prototype.countLengthAfterMerge = function() {
  var results = [];
  this.forEach(function(subArray) {
    subArray.forEach(function(subArrayValue) {
      results.push(subArrayValue);
    });
  });
  return results.length;
};

const ls = localStorage();
const cateringObjInitState = [];

export const addItemToCatering = orderItem => {
  let cateringObj = getLsCatering();
  if (!cateringObj) resetCatering();

  const orderIndex = cateringObj.findIndex(
    product => product.id === orderItem.id
  );

  if (orderIndex >= 0) {
    cateringObj[orderIndex].orderlines.push({ ...orderItem.orderlines });
  } else {
    cateringObj.push({ ...orderItem, orderlines: [orderItem.orderlines] });
  }

  setLsCatering(cateringObj);

  return getCateringItemCount();
};

export const removeItemFromCatering = id => {
  let cateringObj = getLsCatering();
  const newCateringObj = [];
  for (let index = 0; cateringObj.length > index; index++) {
    const productIndex = cateringObj[index].orderlines.findIndex(
      item => item.id === id
    );

    if (productIndex >= 0)
      cateringObj[index].orderlines.splice(productIndex, 1);

    if (cateringObj[index].orderlines.length > 0) {
      newCateringObj.push(cateringObj[index]);
    }
  }

  setLsCatering(newCateringObj);

  return getCateringItemCount();
};

export const getCateringItemCount = () => {
  const count = getLsCatering().map(product => product.orderlines);
  return count.countLengthAfterMerge();
};

export const resetCatering = () => {
  setLsCatering(cateringObjInitState);
  return cateringObjInitState.length;
};

export const setLsCatering = cateringObj =>
  ls.setItem(CATERING_STORAGE_KEY, JSON.stringify(cateringObj));

export const getLsCatering = () =>
  ls.getItem(CATERING_STORAGE_KEY)
    ? JSON.parse(ls.getItem(CATERING_STORAGE_KEY))
    : cateringObjInitState;

export const setVariant = (productId, variantType, selectedVariant) => {
  const savedVariants = JSON.parse(ls.getItem('variants')) || {};

  ls.setItem(
    'variants',
    JSON.stringify({
      ...savedVariants,
      [productId]: {
        variantType,
        selectedVariant
      }
    })
  );

  return {
    ...savedVariants,
    [productId]: {
      variantType,
      selectedVariant
    }
  };
};

const setLabel = product => {
  switch (product.unit) {
    case 'per styck':
      return 'st';
    case 'per person':
      return 'portioner';
    default:
      return 'st';
  }
};
const setSingularLabel = product => {
  switch (product.unit) {
    case 'per styck':
      return 'st';
    case 'per person':
      return 'portion';
    default:
      return 'st';
  }
};

export const mapCateredMealToProductCard = items =>
  items?.map(item => {
    const filteredVariants = item?.variants?.filter(
      x => x.storeDetails && x.visible
    );

    const variants = filteredVariants?.map((variant, index) => {
      return {
        ...variant,
        isChecked: index === 0
      };
    });
    return {
      ...item,
      image: setImage(item.variants[0].images, 0, IMAGE_PATHS.CATERING),
      label: setLabel(item),
      singularLabel: setSingularLabel(item),
      variants: groupby(variants, variant => variant.name)
    };
  });
